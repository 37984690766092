<template>
  <div class="container">
    <div class="timezone">
      Local Timezone: {{ timezone }}
    </div>
    Clickup Timezone:
    <v-select
      :options="timezoneSelected"
      @input="setTimeZone"
      v-model="selected"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import MtApi from "@/agGridV2/helpers/mt-api.helper";

const listTz = [
  {
    "label": "America/Chicago (GMT-06:00)",
    "tzCode": "America/Chicago",
    "name": "(GMT-06:00) Chicago, Houston, San Antonio, Dallas, Austin",
    "utc": "-06:00"
  },
  {
    "label": "Europe/Moscow (GMT+03:00)",
    "tzCode": "Europe/Moscow",
    "name": "(GMT+03:00) Moscow, Saint Petersburg, Nizhniy Novgorod, Kazan, Rostov-na-Donu",
    "utc": "+03:00"
  }
];

export default {
  name: 'Profile',
  components: {
    vSelect
  },
  props: {
    timezoneSelected: {
      type: Array,
      default() {
        return listTz
      }
    },
  },
  data() {
    return {
      selected: this.value,
      setTimeZone: null,
      timezone: '',
      isEfficiencyOnlyValue: false
    }
  },

  computed: {
    ...mapGetters(['isEfficiencyOnly', 'me'])
  },

  mounted() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.isEfficiencyOnlyValue = this.isEfficiencyOnly
    this.selected = listTz.find(tz => tz.tzCode === localStorage.getItem('timezone'))
    this.setTimeZone = async (value) => {
      const data = {
        event: 'update_staff',
        field: {
          name: 'clickup_user_timezone',
          value: this.selected.tzCode
        }
      }

      console.log('setTimeZone', value, data)
      await MtApi.updateStaff(localStorage.getItem('user_id'), data)
    }
  },

  methods: {
    ...mapMutations(['setViewLoader']),
    ...mapActions(['fetchActiveUser'])
  }
}
</script>

<style lang="scss" scoped>
.timezone {
  margin-bottom: 30px;
}
</style>
